import { formatDateToYYYYMMDDForInput } from '@/utils/date/date';
import { Input } from '@chakra-ui/react';
import { t } from 'i18next';
import { ChangeEvent, FC, memo } from 'react';

type CustomFieldDateProps = {
  required?: boolean;
  handleError?: (customFieldId: number, errorMessage: string) => void;
  onChange: (input: { customFieldId: number; value: Date | null }) => void;
  value: Date | null;
  customFieldId: number;
  disabled?: boolean;
};

export const FIELD_DATE_EMPTY_VALUE = null;
export const validateDateRequired = (value: Date | null) => value !== FIELD_DATE_EMPTY_VALUE;

const CustomFieldDate: FC<CustomFieldDateProps> = (props: CustomFieldDateProps) => {
  const { required = false, onChange, value, customFieldId, disabled = false, handleError } = props;

  const valueString = value ? formatDateToYYYYMMDDForInput(value) : '';

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value === '' ? null : new Date(event.target.value);

    if (validateDateRequired(date)) {
      handleError?.(customFieldId, '');
    } else {
      if (required) handleError?.(customFieldId, t('form.validation.required'));
    }

    onChange({
      customFieldId,
      value: date,
    });
  };

  return <Input type='date' value={valueString} onChange={onInputChange} isDisabled={disabled} />;
};

export default memo(CustomFieldDate);
