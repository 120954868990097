import { UserType } from '@/common/components/UserSelectInput';
import {
  IWorkOrderCustomFieldDateValueInput,
  IWorkOrderCustomFieldDatetimeValueInput,
  IWorkOrderCustomFieldFloatValueInput,
  IWorkOrderCustomFieldIntValueInput,
  IWorkOrderCustomFieldSelectValueInput,
  IWorkOrderCustomFieldTextValueInput,
  IWorkOrderCustomFieldUserValueInput,
} from '@/modules/workOrders/types/workOrder';
import type { CustomFieldFileValueType } from './CustomFieldFile';
import type { CustomFieldItemProps } from './CustomFieldItem.types';

type CustomFieldId = CustomFieldItemProps['customField']['id'];

export const getCustomFieldTextValues = (
  customFieldId: CustomFieldId,
  customFieldTextValues: IWorkOrderCustomFieldTextValueInput[]
) => {
  const customFieldTextValue = customFieldTextValues.find(
    (value) => value.customFieldId === customFieldId
  );
  return customFieldTextValue ? customFieldTextValue.value : '';
};

export const getCustomFieldIntValues = (
  customFieldId: CustomFieldId,
  customFieldIntValues: IWorkOrderCustomFieldIntValueInput[]
) => {
  const customFieldIntValue = customFieldIntValues.find(
    (value) => value.customFieldId === customFieldId
  );
  return customFieldIntValue ? customFieldIntValue.value : null;
};

export const getCustomFieldFloatValues = (
  customFieldId: CustomFieldId,
  customFieldFloatValues: IWorkOrderCustomFieldFloatValueInput[]
) => {
  const customFieldFloatValue = customFieldFloatValues.find(
    (value) => value.customFieldId === customFieldId
  );
  return customFieldFloatValue ? customFieldFloatValue.value : null;
};

export const getCustomFieldSelectValues = (
  customFieldId: CustomFieldId,
  customFieldSelectValues: IWorkOrderCustomFieldSelectValueInput[]
) => {
  // NOTE 単数選択、複数選択ともに値なしの場合は、customFieldSelectLocalValues配列に当該customFieldIdの要素が含まれない
  // 複数選択で複数選択肢を選択した場合は、customFieldSelectLocalValues配列に当該customFieldIdの要素が複数含まれる
  return customFieldSelectValues.flatMap((value) =>
    value.customFieldId === customFieldId ? [value.optionId] : []
  );
};

export const getCustomFieldDateValues = (
  customFieldId: CustomFieldId,
  customFieldDateValues: IWorkOrderCustomFieldDateValueInput[]
) => {
  const customFieldDateValue = customFieldDateValues.find(
    (value) => value.customFieldId === customFieldId
  );
  return customFieldDateValue?.value ?? null;
};

export const getCustomFieldDatetimeValues = (
  customFieldId: CustomFieldId,
  customFieldDatetimeValues: IWorkOrderCustomFieldDatetimeValueInput[]
) => {
  const customFieldDatetimeValue = customFieldDatetimeValues.find(
    (value) => value.customFieldId === customFieldId
  );
  return customFieldDatetimeValue?.value ?? null;
};

export const getCustomFieldUserValues = (
  customFieldId: CustomFieldId,
  customFieldUserValues: IWorkOrderCustomFieldUserValueInput[]
) => {
  return customFieldUserValues.flatMap((value) =>
    value.customFieldId === customFieldId
      ? [
          {
            userId: value.userId,
          } satisfies UserType,
        ]
      : []
  );
};

export const getCustomFieldFileValues = (
  customFieldId: CustomFieldId,
  customFieldFileValues: CustomFieldFileValueType[]
) => {
  return customFieldFileValues.filter((value) => value.customFieldId === customFieldId);
};
