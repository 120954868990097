import { useCallback } from 'react';
import {
  generateNewCustomFieldDateValues,
  generateNewCustomFieldDatetimeValues,
  generateNewCustomFieldFloatValues,
  generateNewCustomFieldIntValues,
  generateNewCustomFieldSelectValues,
  generateNewCustomFieldTextValues,
  generateNewCustomFieldUserValues,
} from './CustomFieldItem.onChange';
import { CustomFieldItemProps } from './CustomFieldItem.types';

export const useCustomFieldItem = (props: CustomFieldItemProps) => {
  const {
    customFieldTextValues = [],
    customFieldIntValues = [],
    customFieldFloatValues = [],
    customFieldSelectValues = [],
    customFieldDatetimeValues = [],
    customFieldDateValues = [],
    customFieldUserValues = [],
    changeCustomFieldTextValues,
    changeCustomFieldIntValues,
    changeCustomFieldFloatValues,
    changeCustomFieldSelectValues,
    changeCustomFieldDatetimeValues,
    changeCustomFieldDateValues,
    changeCustomFieldUserValues,
  } = props;

  const onCustomFieldTextValueChange = useCallback(
    (input: { customFieldId: number; value: string }) => {
      changeCustomFieldTextValues(generateNewCustomFieldTextValues(customFieldTextValues, input));
    },
    [customFieldTextValues, changeCustomFieldTextValues]
  );

  const onCustomFieldIntValueChange = useCallback(
    (input: { customFieldId: number; value: number | null }) => {
      changeCustomFieldIntValues(generateNewCustomFieldIntValues(customFieldIntValues, input));
    },
    [customFieldIntValues, changeCustomFieldIntValues]
  );

  const onCustomFieldFloatValueChange = useCallback(
    (input: { customFieldId: number; value: number | null }) => {
      changeCustomFieldFloatValues(
        generateNewCustomFieldFloatValues(customFieldFloatValues, input)
      );
    },
    [customFieldFloatValues, changeCustomFieldFloatValues]
  );

  const onCustomFieldSelectValueChange = useCallback(
    (input: {
      customFieldId: number;
      values: number[];
    }) => {
      changeCustomFieldSelectValues(
        generateNewCustomFieldSelectValues(customFieldSelectValues, input)
      );
    },
    [customFieldSelectValues, changeCustomFieldSelectValues]
  );

  const onCustomFieldDateValueChange = useCallback(
    (input: { customFieldId: number; value: Date | null }) => {
      changeCustomFieldDateValues(generateNewCustomFieldDateValues(customFieldDateValues, input));
    },
    [customFieldDateValues, changeCustomFieldDateValues]
  );

  const onCustomFieldUserValueChange = useCallback(
    (input: { customFieldId: number; userIds: string[] }) => {
      changeCustomFieldUserValues(generateNewCustomFieldUserValues(customFieldUserValues, input));
    },
    [customFieldUserValues, changeCustomFieldUserValues]
  );

  const onCustomFieldDatetimeValueChange = useCallback(
    (input: { customFieldId: number; value: Date | null }) => {
      changeCustomFieldDatetimeValues(
        generateNewCustomFieldDatetimeValues(customFieldDatetimeValues, input)
      );
    },
    [customFieldDatetimeValues, changeCustomFieldDatetimeValues]
  );

  return {
    onCustomFieldTextValueChange,
    onCustomFieldIntValueChange,
    onCustomFieldFloatValueChange,
    onCustomFieldSelectValueChange,
    onCustomFieldDateValueChange,
    onCustomFieldUserValueChange,
    onCustomFieldDatetimeValueChange,
  } as const;
};
