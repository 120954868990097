import { WorkOrderCustomFieldType } from '@/graphql/types';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo, useCallback, useState } from 'react';
import { InputTag } from '../../InputTag';
import { CustomFieldDate } from './CustomFieldDate';
import { CustomFieldDatetime } from './CustomFieldDatetime';
import { CustomFieldFile } from './CustomFieldFile';
import { CustomFieldFloat } from './CustomFieldFloat';
import { CustomFieldInt } from './CustomFieldInt';
import {
  getCustomFieldDateValues,
  getCustomFieldDatetimeValues,
  getCustomFieldFileValues,
  getCustomFieldFloatValues,
  getCustomFieldIntValues,
  getCustomFieldSelectValues,
  getCustomFieldTextValues,
  getCustomFieldUserValues,
} from './CustomFieldItem.getValue';
import { useCustomFieldItem } from './CustomFieldItem.hooks';
import { CustomFieldItemProps } from './CustomFieldItem.types';
import { CustomFieldSelect } from './CustomFieldSelect';
import { CustomFieldText } from './CustomFieldText';
import { CustomFieldUser } from './CustomFieldUser';

const CustomFieldItem: FC<CustomFieldItemProps> = (props: CustomFieldItemProps) => {
  const {
    required = false,
    inputTagProps,
    customField,
    customFieldTextValues = [],
    customFieldIntValues = [],
    customFieldFloatValues = [],
    customFieldSelectValues = [],
    customFieldDatetimeValues = [],
    customFieldDateValues = [],
    customFieldFileValues = [],
    customFieldUserValues = [],
    onAddCustomFieldFileValues,
    onRemoveCustomFieldFileValue,
    getFileUploadUrls,
    onUpdateCustomFieldFileValue,
    onCreateTextSuggest,
    assetId,
    suggestible = false,
  } = props;

  const [customFieldErrorMessage, setCustomFieldErrorMessage] = useState<string>('');

  const handleCustomFieldError = useCallback(
    (customFieldId: number, errorMessage: string) => {
      if (customFieldId !== customField.id) return;
      setCustomFieldErrorMessage(errorMessage);
    },
    [customField.id]
  );

  const {
    onCustomFieldTextValueChange,
    onCustomFieldIntValueChange,
    onCustomFieldFloatValueChange,
    onCustomFieldSelectValueChange,
    onCustomFieldDatetimeValueChange,
    onCustomFieldDateValueChange,
    onCustomFieldUserValueChange,
  } = useCustomFieldItem(props);

  return (
    <Box bg='neutral.0' borderRadius='md' px={4} py={2} mx={2} my={4} key={customField.id}>
      <FormControl isInvalid={!!customFieldErrorMessage}>
        <FormLabel color='neutral.800'>
          <Flex gap={2} alignItems='center'>
            {customField.label}
            {inputTagProps && <InputTag {...inputTagProps} />}
          </Flex>
        </FormLabel>
        {customField.type === WorkOrderCustomFieldType.Text ? (
          <CustomFieldText
            required={required}
            customFieldId={customField.id}
            value={getCustomFieldTextValues(customField.id, customFieldTextValues)}
            onChange={onCustomFieldTextValueChange}
            onCreateTextSuggest={(text: string) => {
              if (!onCreateTextSuggest) throw new Error();
              return onCreateTextSuggest(customField.label, text);
            }}
            suggestible={suggestible}
            customFieldTextConfig={customField.customFieldTextConfig}
            handleError={handleCustomFieldError}
          />
        ) : customField.type === WorkOrderCustomFieldType.Float ? (
          <CustomFieldFloat
            required={required}
            customFieldId={customField.id}
            value={getCustomFieldFloatValues(customField.id, customFieldFloatValues)}
            onChange={onCustomFieldFloatValueChange}
            customFieldNumberConfig={customField.customFieldFloatConfig}
            handleError={handleCustomFieldError}
          />
        ) : customField.type === WorkOrderCustomFieldType.Int ? (
          <CustomFieldInt
            required={required}
            customFieldId={customField.id}
            value={getCustomFieldIntValues(customField.id, customFieldIntValues)}
            onChange={onCustomFieldIntValueChange}
            customFieldNumberConfig={customField.customFieldIntConfig}
            handleError={handleCustomFieldError}
          />
        ) : customField.type === WorkOrderCustomFieldType.Date ? (
          <CustomFieldDate
            required={required}
            customFieldId={customField.id}
            value={getCustomFieldDateValues(customField.id, customFieldDateValues)}
            onChange={onCustomFieldDateValueChange}
            handleError={handleCustomFieldError}
          />
        ) : customField.type === WorkOrderCustomFieldType.Datetime ? (
          <CustomFieldDatetime
            required={required}
            customFieldId={customField.id}
            value={getCustomFieldDatetimeValues(customField.id, customFieldDatetimeValues)}
            onChange={onCustomFieldDatetimeValueChange}
            handleError={handleCustomFieldError}
          />
        ) : customField.type === WorkOrderCustomFieldType.Select ? (
          <CustomFieldSelect
            required={required}
            label={customField.label}
            values={getCustomFieldSelectValues(customField.id, customFieldSelectValues)}
            customFieldId={customField.id}
            options={customField.selectItems}
            customFieldSelectConfig={customField.customFieldSelectConfig}
            onChange={onCustomFieldSelectValueChange}
            assetId={assetId}
            defaults={customField.defaults}
            handleError={handleCustomFieldError}
          />
        ) : customField.type === WorkOrderCustomFieldType.User ? (
          <CustomFieldUser
            required={required}
            label={customField.label}
            customFieldId={customField.id}
            values={getCustomFieldUserValues(customField.id, customFieldUserValues)}
            onChange={onCustomFieldUserValueChange}
            handleError={handleCustomFieldError}
          />
        ) : (
          customField.type === WorkOrderCustomFieldType.File && (
            <CustomFieldFile
              required={required}
              getFileUploadUrls={getFileUploadUrls}
              customFieldId={customField.id}
              values={getCustomFieldFileValues(customField.id, customFieldFileValues)}
              onAddCustomFieldFileValues={onAddCustomFieldFileValues}
              onRemoveCustomFieldFileValue={onRemoveCustomFieldFileValue}
              onUpdateCustomFieldFileValue={onUpdateCustomFieldFileValue}
              handleError={handleCustomFieldError}
            />
          )
        )}
        {customFieldErrorMessage && <FormErrorMessage>{customFieldErrorMessage}</FormErrorMessage>}
      </FormControl>
    </Box>
  );
};

export default memo(CustomFieldItem);
