import { Maybe } from '@/common/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Flex, UseRadioProps, useRadio, useRadioGroup } from '@chakra-ui/react';
import { FC, ReactNode, memo, useEffect } from 'react';

type RadioCardProps = {
  children: ReactNode;
  isDisabled?: boolean;
  handleResetRadio: () => void;
  isChecked: boolean;
} & UseRadioProps;

interface CustomFieldOptionType {
  value: string;
  id: number;
}

type CustomFieldRadioProps = {
  onChange: (input: { customFieldId: number; values: number[] }) => void;
  customFieldId: number;
  optionId: Maybe<number>;
  options: CustomFieldOptionType[];
  defaults?: number[];
  disabled?: boolean;
};

const RadioCard: FC<RadioCardProps> = (props: RadioCardProps) => {
  const { isDisabled = false, handleResetRadio, isChecked } = props;
  // stateを使うとうまくundefined状態にできなかったので、isCheckedを使う
  const { getInputProps, getRadioProps } = useRadio(props);

  const onClick = (e: React.MouseEvent) => {
    if (isChecked) {
      e.preventDefault();
      handleResetRadio();
    }
  };

  return (
    <Box as='label' width='100%' mx='0'>
      <input {...getInputProps()} disabled={isDisabled} />
      <Box
        {...getRadioProps()}
        fontSize='md'
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        borderWidth='1px'
        borderRadius='md'
        bg={isChecked ? 'primary.500' : 'transparent'}
        color={isChecked ? 'neutral.0' : 'primary.500'}
        _focus={{
          boxShadow: 'outline',
        }}
        py={2}
        onClick={onClick}
        px={{ base: 0, md: 4 }}
        textAlign='center'
      >
        {props.children}
      </Box>
    </Box>
  );
};

const CustomFieldRadio: FC<CustomFieldRadioProps> = (props: CustomFieldRadioProps) => {
  const {
    onChange,
    customFieldId,
    optionId: defaultOptionId,
    defaults = [],
    options,
    disabled = false,
  } = props;

  const { t_errors } = useTranslation();
  const optionId = defaultOptionId ? defaultOptionId.toString() : undefined;

  const handleRadio = (optionIdString?: string) => {
    const optionId: Maybe<number> = optionIdString ? Number(optionIdString) : undefined;
    if (optionId) {
      const option = options.find((option) => option.id === optionId);
      if (!option) throw new Error('Impossible');
    }

    onChange({
      customFieldId: customFieldId,
      values: optionId === undefined ? [] : [optionId],
    });
  };

  const { getRootProps, getRadioProps, isDisabled } = useRadioGroup({
    defaultValue: optionId,
    onChange: handleRadio,
    isDisabled: disabled,
  });

  // デフォルト値が設定されている場合は、clearしない。
  const isClearable = defaults.length === 0;

  useEffect(() => {
    // defaultsは最初の項目だけ設定する;
    if (optionId) return;
    if (defaults[0] === undefined) return;

    const option = options.find((option) => option.id === defaults[0]);
    if (!option) throw new Error(t_errors('default-value-can-not-set'));
    // デフォルト値が設定される場合、ここでセットしておく
    onChange({
      customFieldId: customFieldId,
      values: [option.id],
    });
  });

  const handleResetRadio = () => {
    if (!isClearable) return;
    handleRadio();
  };

  return (
    <Flex
      {...getRootProps()}
      direction={{
        base: 'column',
        md: 'row',
      }}
      gap={1}
    >
      {options.map((option) => {
        // TODO 下の処理は削除する
        if (option.id === undefined) return null;
        const id = option.id.toString();

        return (
          <RadioCard
            key={id}
            {...getRadioProps({ value: id })}
            isDisabled={isDisabled}
            handleResetRadio={handleResetRadio}
            isChecked={id === optionId}
          >
            {option.value}
          </RadioCard>
        );
      })}
    </Flex>
  );
};

export default memo(CustomFieldRadio);
