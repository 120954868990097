import UserSelectInput, { UserType } from '@/common/components/UserSelectInput';
import { t } from 'i18next';
import { type FC, memo } from 'react';

type ChangeInputType = { customFieldId: number; userIds: string[] };

type CustomFieldUserProps = {
  required?: boolean;
  handleError?: (customFieldId: number, errorMessage: string) => void;
  customFieldId: number;
  label: string;
  onChange: (input: ChangeInputType) => void;
  values: UserType[];
};

export const validateUserRequired = (values: UserType[]) => values.length > 0;

const CustomFieldUser: FC<CustomFieldUserProps> = (props: CustomFieldUserProps) => {
  const { required = false, handleError, customFieldId, label, values, onChange } = props;

  const handleUserSelectInput = (userValues: UserType[]) => {
    if (validateUserRequired(userValues)) {
      handleError?.(customFieldId, '');
    } else {
      if (required) handleError?.(customFieldId, t('form.validation.required'));
    }
    onChange({ customFieldId, userIds: userValues.map((value) => value.userId) });
  };

  return <UserSelectInput label={label} values={values} onChange={handleUserSelectInput} />;
};

export default memo(CustomFieldUser);
