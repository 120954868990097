import { formatDateToYYYYMMDDHHmmForInput } from '@/utils/date/date';
import { Input } from '@chakra-ui/react';
import { t } from 'i18next';
import { ChangeEvent, FC, memo } from 'react';

type CustomFieldDatetimeProps = {
  required?: boolean;
  handleError?: (customFieldId: number, errorMessage: string) => void;
  onChange: (input: { customFieldId: number; value: Date | null }) => void;
  value: Date | null;
  customFieldId: number;
  disabled?: boolean;
};

export const FIELD_DATETIME_EMPTY_VALUE = null;
export const validateDatetimeRequired = (value: Date | null) =>
  value !== FIELD_DATETIME_EMPTY_VALUE;

const CustomFieldDatetime: FC<CustomFieldDatetimeProps> = (props: CustomFieldDatetimeProps) => {
  const { required = false, handleError, onChange, value, customFieldId, disabled = false } = props;

  const valueString = value ? formatDateToYYYYMMDDHHmmForInput(value) : '';

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const date = event.target.value === '' ? null : new Date(event.target.value);
    if (validateDatetimeRequired(date)) {
      handleError?.(customFieldId, '');
    } else {
      if (required) handleError?.(customFieldId, t('form.validation.required'));
    }

    onChange({
      customFieldId,
      value: date,
    });
  };

  return (
    <Input
      type='datetime-local'
      value={valueString}
      onChange={onInputChange}
      isDisabled={disabled}
    />
  );
};

export default memo(CustomFieldDatetime);
