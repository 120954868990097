import {
  IWorkOrderCustomFieldDateValueInput,
  IWorkOrderCustomFieldDatetimeValueInput,
  IWorkOrderCustomFieldFloatValueInput,
  IWorkOrderCustomFieldIntValueInput,
  IWorkOrderCustomFieldSelectValueInput,
  IWorkOrderCustomFieldTextValueInput,
  IWorkOrderCustomFieldUserValueInput,
} from '@/modules/workOrders/types/workOrder';
import { deepCopy } from '@/utils/copy/copy';
import { FIELD_DATE_EMPTY_VALUE } from './CustomFieldDate/internal/CustomFieldDate.component';
import { FIELD_DATETIME_EMPTY_VALUE } from './CustomFieldDatetime/internal/CustomFieldDatetime.component';
import { FIELD_FLOAT_EMPTY_VALUE } from './CustomFieldFloat/internal/CustomFieldFloat.component';
import { FIELD_INT_EMPTY_VALUE } from './CustomFieldInt/internal/CustomFieldInt.component';

export const generateNewCustomFieldTextValues = (
  customFieldTextValues: IWorkOrderCustomFieldTextValueInput[],
  input: { customFieldId: number; value: string }
) => {
  const { customFieldId, value } = input;
  const index = customFieldTextValues.findIndex(
    (value) => value.customFieldId === input.customFieldId
  );
  const ret = deepCopy(customFieldTextValues);

  if (index >= 0) {
    ret[index].value = value;
  } else {
    ret.push({ customFieldId, value });
  }
  return ret;
};

export const generateNewCustomFieldIntValues = (
  customFieldIntValues: IWorkOrderCustomFieldIntValueInput[],
  input: { customFieldId: number; value: number | null }
) => {
  const { customFieldId, value } = input;
  const index = customFieldIntValues.findIndex(
    (value) => value.customFieldId === input.customFieldId
  );
  const ret = deepCopy(customFieldIntValues);

  if (index >= 0) {
    if (value === FIELD_INT_EMPTY_VALUE) {
      ret.splice(index, 1);
    } else {
      ret[index].value = value;
    }
  } else {
    if (value === FIELD_INT_EMPTY_VALUE) return ret;
    ret.push({ customFieldId, value });
  }
  return ret;
};

export const generateNewCustomFieldFloatValues = (
  customFieldFloatValues: IWorkOrderCustomFieldFloatValueInput[],
  input: { customFieldId: number; value: number | null }
) => {
  const { customFieldId, value } = input;
  const index = customFieldFloatValues.findIndex(
    (value) => value.customFieldId === input.customFieldId
  );
  const ret = deepCopy(customFieldFloatValues);

  if (index >= 0) {
    if (value === FIELD_FLOAT_EMPTY_VALUE) {
      ret.splice(index, 1);
    } else {
      ret[index].value = value;
    }
  } else {
    if (value === FIELD_FLOAT_EMPTY_VALUE) return ret;
    ret.push({ customFieldId, value });
  }
  return ret;
};

export const generateNewCustomFieldSelectValues = (
  customFieldSelectValues: IWorkOrderCustomFieldSelectValueInput[],
  // NOTE: valuesには前回までに選択した値も入っている
  input: { customFieldId: number; values: number[] }
) => {
  const { customFieldId, values } = input;

  const otherSelectValues = customFieldSelectValues.filter(
    (value) => value.customFieldId !== customFieldId
  );
  const targetSelectValues = (values ?? []).map((optionId) => ({ customFieldId, optionId }));

  return [...otherSelectValues, ...targetSelectValues];
};

export const generateNewCustomFieldDateValues = (
  customFieldDateValues: IWorkOrderCustomFieldDateValueInput[],
  input: { customFieldId: number; value: Date | null }
) => {
  const ret = deepCopy(customFieldDateValues);

  const { customFieldId, value } = input;
  const index = ret.findIndex((value) => value.customFieldId === input.customFieldId);

  if (index >= 0) {
    if (value === FIELD_DATE_EMPTY_VALUE) {
      ret.splice(index, 1);
    } else {
      ret[index].value = value;
    }
  } else {
    if (value === FIELD_DATE_EMPTY_VALUE) return ret;
    ret.push({ customFieldId, value });
  }

  return ret;
};

export const generateNewCustomFieldDatetimeValues = (
  customFieldDatetimeValues: IWorkOrderCustomFieldDatetimeValueInput[],
  input: { customFieldId: number; value: Date | null }
) => {
  const ret = deepCopy(customFieldDatetimeValues);

  const { customFieldId, value } = input;
  const index = ret.findIndex((value) => value.customFieldId === input.customFieldId);

  if (index >= 0) {
    if (value === FIELD_DATETIME_EMPTY_VALUE) {
      ret.splice(index, 1);
    } else {
      ret[index].value = value;
    }
  } else {
    if (value === FIELD_DATETIME_EMPTY_VALUE) return ret;
    ret.push({ customFieldId, value });
  }

  return ret;
};

export const generateNewCustomFieldUserValues = (
  customFieldUserValues: IWorkOrderCustomFieldUserValueInput[],
  input: { customFieldId: number; userIds: string[] }
) => {
  const { customFieldId, userIds } = input;
  // 一度全て削除してから、新しい値を追加する
  return [
    ...customFieldUserValues.filter((value) => value.customFieldId !== customFieldId),
    ...(userIds ?? []).map((userId) => ({ customFieldId, userId })),
  ];
};
