import { Maybe } from '@/common/types';
import type { ICustomFieldTextConfig } from '@/modules/workOrders/types/workOrderCustomField';
import useTranslation from '@/utils/i18n/useTranslation';
import { Button, Input, Textarea } from '@chakra-ui/react';
import { FC, memo, useCallback, useMemo } from 'react';
import { BsStars } from 'react-icons/bs';

type CustomFieldTextProps = {
  required?: boolean;
  handleError?: (customFieldId: number, errorMessage: string) => void;
  onChange: (input: { customFieldId: number; value: string }) => void;
  value: Maybe<string>;
  customFieldId: number;
  onCreateTextSuggest?: (text: string) => Promise<string | false>;
  suggestible?: boolean;
  disabled?: boolean;
  customFieldTextConfig?: ICustomFieldTextConfig;
};

export const FIELD_TEXT_EMPTY_VALUE = '';
export const validateTextRequired = (value: string) => value !== FIELD_TEXT_EMPTY_VALUE;

const DEFAULT_TEXTAREA_ROWS = 5;

const CustomFieldText: FC<CustomFieldTextProps> = (props: CustomFieldTextProps) => {
  const {
    required = false,
    handleError,
    onChange,
    value,
    customFieldId,
    onCreateTextSuggest,
    suggestible = false,
    disabled = false,
    customFieldTextConfig,
  } = props;

  const { t } = useTranslation();

  const onSuggestButtonClick = useCallback(async () => {
    if (onCreateTextSuggest === undefined) throw new Error('onCreateTextSuggest is undefined');
    const result = await onCreateTextSuggest(value || '');
    if (!result) return;
    onChange({ customFieldId, value: result });
  }, [customFieldId, onChange, onCreateTextSuggest, value]);

  const validate = useCallback(
    (value: string) => {
      if (validateTextRequired(value)) {
        handleError?.(customFieldId, '');
      } else {
        if (required) handleError?.(customFieldId, t('form.validation.required'));
      }
    },
    [customFieldId, required, handleError, t]
  );

  const rows = useMemo(
    () => customFieldTextConfig?.rows || DEFAULT_TEXTAREA_ROWS,
    [customFieldTextConfig]
  );

  return (
    <>
      {rows === 1 ? (
        <Input
          color='neutral.800'
          value={value}
          onChange={(e) => {
            validate(e.target.value);
            onChange({
              customFieldId,
              value: e.target.value,
            });
          }}
          isDisabled={disabled}
          required
        />
      ) : (
        <Textarea
          color='neutral.800'
          value={value}
          onChange={(e) => {
            validate(e.target.value);
            onChange({
              customFieldId,
              value: e.target.value,
            });
          }}
          rows={rows}
          isDisabled={disabled}
          required
        />
      )}
      {suggestible && (
        <Button
          mt={1}
          size='sm'
          leftIcon={<BsStars />}
          colorScheme='primary'
          variant='outline'
          isDisabled={!value}
          onClick={onSuggestButtonClick}
        >
          {t('AI.suggest')}
        </Button>
      )}
    </>
  );
};

export default memo(CustomFieldText);
