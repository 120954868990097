import type { ICustomFieldIntConfig } from '@/modules/workOrders/types/workOrderCustomField';
import useTranslation from '@/utils/i18n/useTranslation';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { memo, useCallback, useMemo } from 'react';
import type { FC } from 'react';

type CustomFieldIntProps = {
  required?: boolean;
  onChange: (input: { customFieldId: number; value: number | null }) => void;
  handleError?: (customFieldId: number, errorMessage: string) => void;
  value: number | null;
  customFieldId: number;
  disabled?: boolean;
  customFieldNumberConfig?: ICustomFieldIntConfig;
};

const DEFAULT_VALIDATION = { min: undefined, max: undefined, interval: undefined };

export const FIELD_INT_EMPTY_VALUE = null;
export const validateIntRequired = (value: number | null) => value !== FIELD_INT_EMPTY_VALUE;

const CustomFieldInt: FC<CustomFieldIntProps> = (props: CustomFieldIntProps) => {
  const {
    required = false,
    onChange,
    value,
    customFieldId,
    disabled = false,
    customFieldNumberConfig,
    handleError,
  } = props;

  const { t } = useTranslation();

  const {
    min,
    max,
    interval: step,
  } = useMemo(() => customFieldNumberConfig ?? DEFAULT_VALIDATION, [customFieldNumberConfig]);

  const validate = useCallback(
    (value: number | null) => {
      if (!validateIntRequired(value)) {
        if (required) handleError?.(customFieldId, t('form.validation.required'));
        // NOTE: nullの場合は整数固有のバリデーションを素通りさせる
        return;
      }

      if (max === undefined && min === undefined) return;

      if (min !== undefined && value < min) {
        handleError?.(
          customFieldId,
          t('form.validation.range-violation-message', { minValue: min, maxValue: max })
        );
        return;
      }

      if (max !== undefined && max < value) {
        handleError?.(
          customFieldId,
          t('form.validation.range-violation-message', { minValue: min, maxValue: max })
        );
        return;
      }

      handleError?.(customFieldId, '');
    },
    [customFieldId, handleError, max, min, required, t]
  );

  const onNumberInputChange = useCallback(
    (valueString: string) => {
      const value = convertToInt(valueString);
      validate(value);
      onChange({ customFieldId, value });
    },
    [validate, onChange, customFieldId]
  );
  return (
    <NumberInput
      defaultValue={value ?? undefined}
      onChange={onNumberInputChange}
      isDisabled={disabled}
      min={min}
      max={max}
      step={step}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};

const convertToInt = (valueString: string): number | null => {
  return valueString === '' ? null : Number(valueString);
};

export default memo(CustomFieldInt);
