import MultipleLayerCheckbox from '@/common/components/MultipleLayerCheckbox';
import MultipleLayerSelect from '@/common/components/MultipleLayerSelect';
import {
  CustomFieldSelectItemType,
  CustomFieldSelectOptionType,
  generateItemValueBySelectOption,
  hasAssetDependencyOrNoDependency,
} from '@/common/components/customFields';
import { Maybe, OptionItem, OptionValue } from '@/common/types';
import type { ICustomFieldSelectConfig } from '@/modules/workOrders/types/workOrderCustomField';
import useTranslation from '@/utils/i18n/useTranslation';
import { t } from 'i18next';
import { FC, memo, useEffect } from 'react';
import { CustomFieldRadio } from '../../CustomFieldRadio';

type CustomFieldSelectProps = {
  required?: boolean;
  onChange: (input: { customFieldId: number; values: number[] }) => void;
  handleError?: (customFieldId: number, errorMessage: string) => void;
  label?: string;
  customFieldId: number;
  values: number[];
  options: CustomFieldSelectItemType[];
  defaults: number[];
  assetId: Maybe<number>;
  disabled?: boolean;
  customFieldSelectConfig?: ICustomFieldSelectConfig;
};

export const validateSelectRequired = <T = OptionValue[] | OptionValue | undefined>(value: T) =>
  Array.isArray(value) && value.length > 0;

const CustomFieldSelect: FC<CustomFieldSelectProps> = (props: CustomFieldSelectProps) => {
  const {
    required = false,
    handleError,
    onChange,
    label,
    customFieldId,
    values,
    defaults,
    options,
    assetId,
    disabled = false,
    customFieldSelectConfig,
  } = props;
  const defaultValue = defaults.length === 0 ? null : defaults[0];

  const { t_errors } = useTranslation();

  const isClearable = !defaultValue;
  const isMulti = customFieldSelectConfig?.multiple ?? false;
  const hasCategory = options.some((item) => !!item.category);
  const flatOptions: CustomFieldSelectOptionType[] = options.flatMap(({ category, option }) => {
    if (category) {
      return category.selectItems.map(({ option }) => {
        if (!option) throw new Error('Invalid select items(category option)');
        return option;
      });
    }
    if (option) {
      return [option];
    }
    throw new Error('Invalid select items');
  });
  const currentOption = flatOptions.find((option) => values.includes(option.id));
  const currentOptions = flatOptions.filter((option) => values.includes(option.id));

  const selectOptions = (() => {
    const selectOptions: OptionItem[] = options.map(generateItemValueBySelectOption);
    const filteredOptions: OptionItem[] = [];
    options.forEach(({ category, option }) => {
      if (!category && !option) throw new Error('Invalid select items');
      if (category) {
        // 個要素に設備の依存関係がある場合にのみ、追加する
        if (
          category.selectItems.some(
            ({ option }) => option && hasAssetDependencyOrNoDependency(option, assetId)
          )
        ) {
          filteredOptions.push({
            id: `category:${category.name}`,
            label: category.name,
            isSelectDisabled: true,
            children: category.selectItems
              .filter(({ option }) => option && hasAssetDependencyOrNoDependency(option, assetId))
              .map(({ option }) => {
                if (!option) throw new Error('Invalid select items(category option)');
                return {
                  id: option.id,
                  label: option.value,
                };
              }),
          });
        }
      }
      if (option) {
        if (hasAssetDependencyOrNoDependency(option, assetId)) {
          filteredOptions.push({
            id: option.id,
            label: option.value,
          });
        }
      }
    });
    // filterした結果が無い場合は、全てのoptionを表示する
    return filteredOptions.length > 0 ? filteredOptions : selectOptions;
  })();

  // biome-ignore lint/correctness/useExhaustiveDependencies: デフォルト値はuseState初期値 useFormのdefaultValuesで設定してください
  useEffect(() => {
    // defaultsは最初の項目だけ設定する;
    if (values.length > 0) return;
    if (defaultValue === null) return;

    const option = flatOptions.find((option) => option.id === defaultValue);
    if (!option) throw new Error(t_errors('default-value-can-not-set'));
    // デフォルト値が設定される場合、ここでセットしておく
    // TODO: デフォルト値が複数あり場合も考慮する
    onChange({
      customFieldId: customFieldId,
      values: [option.id],
    });
  }, []);

  const handleMultiSelect = (input?: OptionValue[]) => {
    // NOTE: 各選択肢の×ボタンを押すことで値がなくなる場合は空配列が返る一方、クリアボタンを押して選択肢を全消去する場合はundefinedが返る
    if (validateSelectRequired(input)) {
      handleError?.(customFieldId, '');
    } else {
      if (required) {
        handleError?.(customFieldId, t('form.validation.required'));
      }
    }

    if (input === undefined) {
      onChange({
        customFieldId: customFieldId,
        // NOTE: クリアボタンを押してundefinedが返るが、状態管理側では選択なしと同じように扱って良い
        values: [],
      });
      return;
    }
    onChange({
      customFieldId: customFieldId,
      values: input.map((entry) => entry as number),
    });
  };

  const handleSingleSelect = (input: OptionValue | null) => {
    const values = input === null ? [] : [input as number];
    if (validateSelectRequired(values)) {
      handleError?.(customFieldId, '');
    } else {
      if (required) {
        handleError?.(customFieldId, t('form.validation.required'));
      }
    }

    onChange({
      customFieldId: customFieldId,
      values,
    });
  };

  return isMulti ? (
    <MultipleLayerCheckbox
      placeholder={label}
      values={currentOptions.map((option) => option.id)}
      options={selectOptions}
      onChange={(values) => handleMultiSelect(values)}
      isClearable={isClearable}
      hasOverlay={true} // 外側をクリックしても閉じるように
    />
  ) : flatOptions.length <= 3 && !hasCategory ? (
    <CustomFieldRadio
      optionId={currentOptions.length > 0 ? currentOptions[0].id : undefined}
      customFieldId={customFieldId}
      options={flatOptions}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <MultipleLayerSelect
      placeholder={label}
      value={currentOption?.id ?? null}
      options={selectOptions}
      onChange={handleSingleSelect}
      isClearable={isClearable}
      label={label ?? ''}
    />
  );
};

export default memo(CustomFieldSelect);
